import React, { useEffect } from 'react';

import { months } from '../../consts/date';
import { useDispatch, useSelector } from 'react-redux';

import { isLoadingAction, snackBarAction } from '../../redux/actions/utilitiesAction';
import { listResponseSelector, token, isLoading } from '../../redux/selectors';

import View from './view';
import {
    createDataAction,
    deleteDataAction,
    listDataAction,
    upDateDataAction,
} from '../../redux/actions/payPlanAction';

export default () => {
    const dispatch = useDispatch();
    const listResponse = useSelector((state) => listResponseSelector(state, 'payPlanReducerV2'));
    const responseToken = useSelector((state) => token(state));
    const isLoadingResponse = useSelector((state) => isLoading(state));

    useEffect(() => {
        if (!listResponse && responseToken) {
            dispatch(isLoadingAction(true));
            dispatch(listDataAction());
        }
    }, [listResponse, responseToken, dispatch]);

    const onRowAdd = (obj, newData, data) => {
        let endRow = { ...data };
        newData = { ...newData, PDF: newData?.VIEW };
        delete endRow.ADJ_ID;
        delete endRow.tableData;
        delete endRow._id;
        delete newData.VIEW;
        const {NAME,DESCRIPTION,PDF} = newData;
        if (!NAME || !DESCRIPTION) {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'Name and Description fields are required',
                    type: 'error',
                })
            );
            return;
        }
        if (PDF && PDF.type !== 'application/pdf') {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'Only PDF files are allowed',
                    type: 'error',
                })
            );
            return;
        }
        if (PDF && PDF.size > 10 * 1024 * 1024) {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'The file is too large',
                    type: 'error',
                })
            );
            return;
        }
        dispatch(isLoadingAction(true));
        dispatch(createDataAction(newData, obj));
    };
    const onRowDelete = (obj, oldData) => {
        dispatch(isLoadingAction(true));
        dispatch(deleteDataAction(oldData._id, obj));
    };
    const onRowEdit = (obj, newData, oldData) => {
        delete newData?.PDF_S3;
        delete newData?.PDF;
        delete newData?.URL_PDF_S3;
        let data = { ...newData,PDF: newData?.VIEW };
        delete data?.VIEW;
        const {DESCRIPTION,PDF} = data;
        let firstchar = data.POSTAMTEXT.charAt(0);
        if (firstchar === '$') {
            data = {
                ...data,
                POSTAMTEXT: data.POSTAMTEXT.slice(1),
            };
        }
        if (!DESCRIPTION) {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'Description is required',
                    type: 'error',
                })
            );
            return;
        }
        if (PDF && PDF.type !== 'application/pdf') {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'Only PDF files are allowed',
                    type: 'error',
                })
            );
            return;
        }
        if (PDF && PDF.size > 10 * 1024 * 1024) {
            dispatch(
                snackBarAction({
                    open: true,
                    msg: 'The file is too large',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(isLoadingAction(true));
        dispatch(upDateDataAction(newData._id, data));
    };

    return (
        <View
            isLoadingResponse={isLoadingResponse}
            onRowAdd={onRowAdd}
            onRowDelete={onRowDelete}
            onRowEdit={onRowEdit}
        />
    );
};
