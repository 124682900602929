import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    PAYPLAN_LIST_START,
    PAYPLAN_LIST_COMPLETE,
    PAYPLAN_LIST_ERROR,
    PAYPLAN_CREATE_START,
    PAYPLAN_CREATE_COMPLETE,
    PAYPLAN_UPDATE_START,
    PAYPLAN_UPDATE_COMPLETE,
    PAYPLAN_UPDATE_ERROR,
    PAYPLAN_DELETE_START,
    PAYPLAN_DELETE_COMPLETE,
    PAYPLAN_DELETE_ERROR,
    PAYPLAN_SEARCH_START,
    PAYPLAN_SEARCH_COMPLETE,
    PAYPLAN_SEARCH_ERROR,
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import { PAYPLAN } from '../../consts/urls';
import { token, idItemSelectedSelector, associateSelector } from '../selectors';

export function* list() {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, PAYPLAN, null, responseToken, 'GET');
        yield put({ type: PAYPLAN_LIST_COMPLETE, results });
    } catch (error) {
        console.log(error);
        const errorResponse = error.response;
        yield put({ type: PAYPLAN_LIST_ERROR, errorResponse });
        yield put(
            snackBarAction({
                open: true,
                msg: 'error Load List: ' + errorResponse.data.message,
                type: 'error',
            })
        );
    }
    yield put(isLoadingAction(false));
}
export function* create({ data }) {
    try {
        const responseToken = yield select(token);
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        const results = yield call(apiCall, PAYPLAN, formData, responseToken, 'POST', {
            'Content-Type': 'multipart/form-data',
        });
        yield put({ type: PAYPLAN_CREATE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* list();
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );

        yield put(isLoadingAction(false));
    } finally {
        yield put(isLoadingAction(false));
    }
}
export function* update({ id, data }) {
    try {
        const responseToken = yield select(token);
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });
        const results = yield call(apiCall, `${PAYPLAN}/${id}`, formData, responseToken, 'PUT', {
            'Content-Type': 'multipart/form-data',
        });
        yield put({ type: PAYPLAN_UPDATE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* list();
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    } finally {
        yield put(isLoadingAction(false));
    }
}
export function* deleteR({ id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${PAYPLAN}/${id}`, null, responseToken, 'DELETE');
        yield put({ type: PAYPLAN_DELETE_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
        yield* list();
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}
export function* search({ id }) {
    try {
        const responseToken = yield select(token);
        const results = yield call(apiCall, `${PAYPLAN}/${id}`, null, responseToken, 'GET');
        yield put({ type: PAYPLAN_SEARCH_COMPLETE, results });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackBarAction({
                open: true,
                msg: 'error create associate: ' + errorResponse.data.message,
                type: 'error',
            })
        );
        yield put(isLoadingAction(false));
    }
}

export default function* payPlan() {
    yield takeLatest(PAYPLAN_LIST_START, list);
    yield takeLatest(PAYPLAN_CREATE_START, create);
    yield takeLatest(PAYPLAN_UPDATE_START, update);
    yield takeLatest(PAYPLAN_DELETE_START, deleteR);
    yield takeLatest(PAYPLAN_SEARCH_START, search);
}
