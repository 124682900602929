import { get } from 'lodash';
import { PictureAsPdfRounded } from '@material-ui/icons';
import {
    TextField,
    Button,
    styled,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import React, { forwardRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { PAYPLAN_LIST_COMPLETE, PAYPLAN_LIST_ERROR } from '../../consts/actionTypes';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const payplanTable = {
    columns: [
        {
            title: 'Description',
            field: 'DESCRIPTION',
            editComponent: (props) => (
                <TextField
                    onChange={(e) => props.onChange(e.target.value)}
                    id='standard-basic'
                    label='Description'
                    variant='outlined'
                    style={{ width: 250, marginTop: '-5px' }}
                    value={props.value}
                />
            ),
        },
        {
            title: 'View',
            field: 'VIEW',
            render: (props) => {
                const url = props?.URL_PDF_S3;
                const filename = props?.NAME;
                function downloadPDF() {
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = filename || 'download.pdf';
                    link.click();
                }
                return props?.URL_PDF_S3 ? (
                    <PictureAsPdfRounded onClick={downloadPDF} style={{ cursor: 'pointer', border: '2px' }} />
                ) : (
                    <></>
                );
            },
            editComponent: (props) => {
                return (
                    <>
                        <Button
                            component='label'
                            role={undefined}
                            variant='contained'
                            style={{ width: 250, padding: '14px', marginTop: '-6px' }}
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload files
                            <VisuallyHiddenInput
                                type='file'
                                accept='application/pdf'
                                onChange={(event) => {
                                    const file = event.target.files[0];

                                    props.onChange(file);
                                }}
                            />
                        </Button>
                    </>
                );
            },
        },
        {
            title: 'Status',
            field: 'STATUS',
            editable: 'onUpdate',
            render: (props) => {
                return props?.STATUS ? <span>{props.STATUS}</span> : <></>;
            },
            editComponent: (props) => {
                return (
                    <FormControl fullWidth>
                        <InputLabel
                            id='demo-simple-select-label'
                            style={{ top: '-6px', left: '14px' }}
                        >
                            Status
                        </InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={props.value}
                            label='Status'
                            style={{width:250, marginTop: '-6px'}}
                            variant='outlined'
                            onChange={(e) => props.onChange(e.target.value)}
                        >
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                            <MenuItem value={'Active'}>Active</MenuItem>
                        </Select>
                    </FormControl>
                );
            },
        },
    ],
    data: [],
};
const initialState = {
    payplanTable: payplanTable,
    listResponse: null,
    singleResponse: null,
    openDialogAction: false,
    actionDialog: 0,
    dinamicTableConf: {
        ...payplanTable,
        columns: [
            {
                title: 'Name',
                field: 'NAME',
                editable: 'onAdd',
                editComponent: (props) => (
                    <TextField
                        onChange={(e) => props.onChange(e.target.value)}
                        id='standard-basic'
                        label='NAME'
                        variant='outlined'
                        style={{ width: 250 }}
                        value={props.value}
                    />
                ),
            },
            ...payplanTable.columns,
        ],
    },
    associateWashout: { ...payplanTable },
};

const newData = (data) => {
    if (!data.length) return [];

    for (const objPayplan of data) {
        objPayplan.POSTAMTEXT = `$${new Intl.NumberFormat().format(objPayplan.POSTAMTEXT)}`;
    }
    return data;
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYPLAN_LIST_COMPLETE:
            return {
                ...state,
                listResponse: get(action, 'results.data'),
                dinamicTableConf: {
                    ...state.dinamicTableConf,
                    data: [...newData(action?.results?.data)],
                },
            };

        default:
            return { ...state };
    }
}
